import React from "react";
import "./contact.css"

export default function Contact() {
    return(
        <div className="contact">
            <div className="contact-table">
                <div className="contact-table-left">
                    <div className="heading">İletişim</div>
                    <div class="contact-about">
                        <div class="contact-info"><i class="fas fa-street-view"></i> Alsancak, Kıbrıs Şehitleri Cd. No:66, 35220 Konak/İzmir</div>
                        <div class="contact-info"><i class="fas fa-envelope"></i> donkisottattoo@gmail.com</div>
                        <div class="contact-info"><i class="fab fa-instagram"></i> instagram.com/donkisottattoostudio</div>
                        <div class="contact-info contact-phone"><i class="fas fa-phone"></i> +90 507 566 93 81</div>
                    </div>
                </div>
                <div className="contact-table-right">
                    <div className="heading">Lokasyon</div>
                    <iframe className="map-frame" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3125.1732904718638!2d27.141255315229497!3d38.43746608152795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bbd85b65ed1cdd%3A0xb5364b9d83e70621!2sDon%20Ki%C5%9Fot%20Tattoo%20Studio!5e0!3m2!1str!2str!4v1580423016917!5m2!1str!2str" frameborder="0" allowfullscreen=""></iframe>
                </div>
            </div>
        </div>
    )
}