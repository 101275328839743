import React, { useState, useEffect } from "react";
import "./header.css"

import {firestore} from "../../firebase";

function useMain() {
    const [main, setMain] = useState([]);

    useEffect(() => {
        firestore.collection("mainScreen").onSnapshot((snapshot) => {
            const newMain = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }))

            setMain(newMain)
        })
    }, [])

    return main
}

export default function Header() {
    const main = useMain();
    const [opacity, setOpacity] = useState(1)

    window.addEventListener("scroll", () => {
        setOpacity(1 - window.pageYOffset * 0.003)
    })

    const scrollPortfolio = () => {
        const portfolio = document.querySelector(".portfolio");

        window.scrollTo({
            left: 0,
            top: portfolio.offsetTop - 80, 
            behavior: "smooth"
        })
    }

    return (
    <div className="header" style={{backgroundImage: main.length > 0 ? "url(" + main[main.length - 1].link +")" : "", backgroundSize: "cover", backgroundPosition: "center", backgroundAttachment: "fixed" }}>
        <div className="header-tint">
            <div className="header-inner-tint">
                <div className="header-tint-content" style={{opacity}}>
                    <div className="header-heading">Donkişot Tattoo</div>
                    <div className="header-desc">
                     Donkişot Tattoo, İzmir'in Alsancak Kıbrıs Şehitleri caddesinde hizmet veren bir dövme stüdyosudur.
                    </div>
                    <div className="header-btn" onClick={() => scrollPortfolio()} >Dövmeleri Keşfet</div>
                    <div className="header-icon-wrapper" onClick={() => scrollPortfolio()}>
                        <i className="fas fa-chevron-down"></i>
                        <i className="fas fa-chevron-down"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}