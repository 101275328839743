import React, { useState, useEffect } from "react" 
import { Link } from "react-router-dom"
import "./galery.css"

import galery from "../galery.json"

import {firestore} from "../../firebase";

function useGaleries() {
    const [galeries, setGaleries] = useState([]);

    useEffect(() => {
        firestore.collection("galery").onSnapshot((snapshot) => {
            console.log(snapshot)
            const newGaleries = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }))

            setGaleries(newGaleries)
        })
    }, [])

    console.log(galeries)

    return galeries
}

export default function Galery() {
    const galeries = useGaleries();

    const [modal, setModal] = useState(false);
    const [source, setSource] = useState("");

    const modalOpen = (item) => {
        setModal(true);
        setSource(item);
    }

    return(
        <div className="galery">
            <button className="galery-back-btn"><i className="fas fa-chevron-left"></i> <Link to="/">Geri Dön</Link></button>
            <div className="heading">Galeri</div>

            <div className={"portfolio-modal" + (modal ? " portfolio-modal-active" : "")} onClick={() => setModal(false)}>
                <div className="portfolio-modal-image-wrapper">
                    <img src={source.link} alt="portfolio tattoo image" className="portfolio-modal-image"/>
                </div>
            </div>
            <div className="portfolio-flex">
                 {
                     galeries.map((item, index) => {
                         return (
                            <div className="portfolio-card" key={index} onClick={() => modalOpen(item)}>
                             <img src={item.link} alt="galery image" className="portfolio-card-image"/>
                            </div>
                         )
                     }).reverse()
                 }
            </div>
        </div>
    )
}