import React, { useState } from "react";
import "./nav.css"

export default function Nav() {
    const [mobile, setMobile] = useState(false);

    const menuScroll = (item) => {
        const team = document.querySelector(".team");
        const portfolio = document.querySelector(".portfolio");
        const contact = document.querySelector(".contact");

        const positions = {
            team, portfolio, contact
        }

        window.scrollTo({
            left: 0,
            top: positions[item].offsetTop - 80, 
            behavior: "smooth"
        })

        if(mobile) {
            setMobile(false)
        }
    }

    return (
    <div className="nav">
        <div className={"mobile-menu" + (mobile ? " mobile-menu-active" : "")} onClick={() => setMobile(false)}>
            <div className="mobile-menu-heading">Donkişot Tattoo</div>

            <div className="mobile-menu-item" onClick={() => window.location.reload()}>Anasayfa</div>
            <div className="mobile-menu-item" onClick={() => menuScroll("team")}>Ekip</div>
            <div className="mobile-menu-item" onClick={() => menuScroll("portfolio")}>Portfolyo</div>
            <div className="mobile-menu-item" onClick={() => menuScroll("contact")}>İletişim</div>
        </div>

        <div className="logo">Donkişot Tattoo</div>
        <div className="menu">
            <div className="menu-item" onClick={() => window.location.reload()}>Anasayfa</div>
            <div className="menu-item" onClick={() => menuScroll("team")}>Ekip</div>
            <div className="menu-item" onClick={() => menuScroll("portfolio")}>Portfolyo</div>
            <div className="menu-item" onClick={() => menuScroll("contact")}>İletişim</div>
            <div className="menu-icon" onClick={() => setMobile(true)}><i className="fas fa-bars"></i></div>
        </div>
    </div>
    )
}