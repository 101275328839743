import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./portfolio.css"

import galery from "../galery.json"

import {firestore} from "../../firebase";

function useGaleries() {
    const [galeries, setGaleries] = useState([]);

    useEffect(() => {
        firestore.collection("galery").onSnapshot((snapshot) => {
            const newGaleries = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }))

            setGaleries(newGaleries)
        })
    }, [])

    return galeries
}


export default function Portfolio() {
    const galeries = useGaleries();

    const [modal, setModal] = useState(false);
    const [source, setSource] = useState("");

    const modalOpen = (item) => {
        setModal(true);
        setSource(item);
    }

    const photos = galeries.map((item, index) => {
        if(index < 20) {
            return(
                <div className="portfolio-card" key={index} onClick={() => modalOpen(item)}>
                    <img src={item.link} alt="portfolio image" className="portfolio-card-image"/>
                </div>
            )
        } else {
            return null
        }
    })

    return(
        <div className="portfolio">
            <div className={"portfolio-modal" + (modal ? " portfolio-modal-active" : "")} onClick={() => setModal(false)}>
                <div className="portfolio-modal-image-wrapper">
                    <img src={source.link} alt="portfolio tattoo image" className="portfolio-modal-image"/>
                </div>
            </div>

            <div className="heading">Porfolyo</div>
            <div className="portfolio-flex">
                {photos.reverse()}
            </div>
            <div className="portfolio-detail-btn" onClick={() => { window.scrollTo({left:0, top: 0 })}}><Link to="galery">Daha Fazla Gör</Link></div>
        </div>
    )
}