import React from "react";
import "./social.css"

export default function Social() {

    return (
        <div className="social">
            <div className="social-tint">
                <div className="social-tint-heading">Bizi Takip Edin</div>
                <div className="social-accounts-flex">
                    <div className="social-accounts-card">
                        <i className="fab fa-instagram"></i>
                        <div className="social-media-platform">Instagram</div>
                        <a href="https://www.instagram.com/donkisottattoostudio" className="social-media-follow" target="_blank">Takip Et</a>
                    </div>
                    
                    <div className="social-accounts-card">
                        <i className="fas fa-at"></i>
                        <div className="social-media-platform">Gmail</div>
                        <a href="mailto:donkisottattoo@gmail.com" className="social-media-follow" target="_blank">Takip Et</a>
                    </div>
                    
                    <div className="social-accounts-card">
                        <i className="fab fa-facebook"></i>
                        <div className="social-media-platform">Facebook</div>
                        <a href="https://www.facebook.com/donkisottattoo/" className="social-media-follow" target="_blank">Takip Et</a>
                    </div>
                    
                    <div className="social-accounts-card">
                        <i className="fab fa-youtube"></i>
                        <div className="social-media-platform">Youtube</div>
                        <a href="https://www.instagram.com/donkisottattoostudio" className="social-media-follow" target="_blank">Takip Et</a>
                    </div>
                </div>
            </div>
        </div>
    )
}