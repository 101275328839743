import React, { useState, useEffect } from "react";
import "./slider.css"

import galery from "../galery.json"

import {firestore} from "../../firebase";

function useGaleries() {
    const [galeries, setGaleries] = useState([]);

    useEffect(() => {
        firestore.collection("galery").onSnapshot((snapshot) => {
            const newGaleries = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }))

            setGaleries(newGaleries)
        })
    }, [])

    return galeries
}


export default function Slider() {
    const galeries = useGaleries();

    const [modal, setModal] = useState(false);
    const [source, setSource] = useState("");

    const modalOpen = (item) => {
        setModal(true);
        setSource(item);
    }

    const slidePrev = () => {
        document.getElementById("slider-flex").scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth'
        })
    }

    const slideNext = () => {
        document.getElementById("slider-flex").scrollTo({
            left: 1000,
            top: 0,
            behavior: 'smooth'
        })
    }

    const photos = galeries.map((item, index) => {
        if(index < 8) {
            return(
                <div key={index} className="slider-card" onClick={() => modalOpen(item)}>
                    <img src={item.link} className="slider-card-image" alt="slider tattoo image" />
                </div>
            )
        } else {
            return null
        }
    })

    return (
    <div className="slider">
        <div className={"slider-modal" + (modal ? " slider-modal-active" : "")} onClick={() => setModal(false)}>
            <div className="slider-modal-image-wrapper">
                <img src={source.link} alt="slider tattoo image" className="slider-modal-image"/>
            </div>
        </div>

        <div className="heading">En son çalışmalarımız</div>

        <div className="slider-prev" onClick={() => slidePrev()}>
            <i className="fas fa-chevron-left"></i>
        </div>

        <div className="slider-next" onClick={() => slideNext()}>
            <i className="fas fa-chevron-right"></i>
        </div>
        
        <div className="slider-flex" id="slider-flex">
            {photos.reverse()}
        </div>
    </div>
    )
}