import React, { useEffect } from 'react';
import {BrowserRouter, HashRouter, Route } from "react-router-dom"
import Nav from "../src/components/nav/nav"
import Header from "../src/components/header/header"
import Slider from "../src/components/slider/slider"
import Team from "../src/components/team/team"
import Social from "../src/components/social/social"
import Portfolio from "../src/components/portfolio/portfolio"
import Contact from "../src/components/contact/contact"
import Galery from "../src/components/galery/galery"
import Footer from "../src/components/footer/footer"
import Admin from "../src/components/admin/admin"
import Panel from './components/panel/panel';
import './App.css';


function App() {

  return (
    <HashRouter>
      <div className="App">
        <Route exact path="/" component={Nav} />
        <Route exact path="/" component={Header} />
        <div className="container">
          <Route exact path="/" component={Slider} />
          {/* <Route exact path="/" component={Team} /> */}
          <Route exact path="/" component={Social} />
          <Route exact path="/" component={Portfolio} />
          <Route exact path="/" component={Contact} />
          <Route path="/galery" component={Galery} />
          <Route path="/admin" component={Admin} />
          <Route path="/panel" component={Panel} />
        </div>
        <Footer />
      </div>
    </HashRouter>
  );
}

export default App;
