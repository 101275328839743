import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/firestore";

export const fbConfig = {
    apiKey: "AIzaSyCKAuOWmshVWaSP1AiF3ZhHQe5dnBOl-bM",
    authDomain: "donkisot-bc269.firebaseapp.com",
    databaseURL: "https://donkisot-bc269.firebaseio.com",
    projectId: "donkisot-bc269",
    storageBucket: "donkisot-bc269.appspot.com",
    messagingSenderId: "865931897724",
    appId: "1:865931897724:web:4ce50a063c15939f45d2f9",
    measurementId: "G-N1QT94Y4PD"
}

if (!firebase.apps.length) {
    firebase.initializeApp(fbConfig);
}
  
const storage = firebase.storage();
const firestore = firebase.firestore();
  
export { storage, firestore };