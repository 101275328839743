import React, { useEffect, useState } from "react";
import { firestore, storage } from "../../firebase";

function useGaleries() {
    const [galeries, setGaleries] = useState([]);

    useEffect(() => {
        firestore.collection("galery").onSnapshot((snapshot) => {
            const newGaleries = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }))

            setGaleries(newGaleries)
        })
    }, [])

    return galeries
}

function useMain() {
    const [main, setMain] = useState([]);

    useEffect(() => {
        firestore.collection("mainScreen").onSnapshot((snapshot) => {
            const newMain = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }))

            setMain(newMain)
        })
    }, [])

    return main
}

function useMembers() {
    const [members, setMembers] = useState([]);

    useEffect(() => {
        firestore.collection("team").onSnapshot((snapshot) => {
            const newMembers = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }))

            setMembers(newMembers)
        })
    }, [])

    return members
}

export default function Panel() {
    const [isOpen, setIsOpen] = useState(false);
    const [compressed, setCompressed] = useState();
    const [compressed2, setCompressed2] = useState();
    const galeries = useGaleries();
    const main = useMain();
    const team = useMembers();

    useEffect(() => {
        if (localStorage.getItem('donkisotSession')) {
            setIsOpen(true);
        } else {
            const pass = prompt('Please enter password');

            if (pass === "turkcanwebsitesi") {
                setIsOpen(true);
                localStorage.setItem('donkisotSession', 'true');
                localStorage.setItem('donkisotSessionDeadline', new Date().getTime() + (1000 * 60 * 60 * 24));
            } else {
                window.location.reload()
            }
        }

        if (Number(localStorage.getItem('donkisotSessionDeadline')) < new Date().getTime()) {
            localStorage.removeItem('donkisotSession')
            localStorage.removeItem('donkisotSessionDeadline')
        }
    }, [])

    const uploadPhoto = (event) => {
        var selectedFile = event.target.files[0];

        setCompressed(selectedFile)
    };

    function uploadGalery() {
        const unique_id = Math.random();

        let ref = storage.ref(
            "galery/" +
            unique_id
        );

        let uploadCard = ref.put(compressed);

        uploadCard.then((snapshot) => {
            if (snapshot.state === "success") {
                snapshot.ref.getDownloadURL().then((url) => {
                    firestore.collection("galery").add({
                        link: url
                    }).then(() => {
                        setTimeout(() => {
                            window.location.reload();
                        }, 1500)
                    });
                });
            }
        });
    }

    function deletePhoto(link) {
        var jobskill_ref = firestore.collection('galery').where('link', '==', link);
        let batch = firestore.batch();

        jobskill_ref
            .get()
            .then(snapshot => {
                snapshot.docs.forEach(doc => {
                    batch.delete(doc.ref);
                });
                return batch.commit();
            })
    }

    const uploadPhotoGiris = (event) => {
        var selectedFile = event.target.files[0];

        setCompressed2(selectedFile)
    };

    function uploadGiris() {
        const unique_id = Math.random();

        let ref = storage.ref(
            "mainScreen/" +
            unique_id
        );

        let uploadCard = ref.put(compressed2);

        uploadCard.then((snapshot) => {
            if (snapshot.state === "success") {
                snapshot.ref.getDownloadURL().then((url) => {    
                    main.forEach((item) => {
                        deletePhotoGiris(item.link, () => {
                            firestore.collection("mainScreen").add({
                                link: url
                            }).then(() => {
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500)
                            });
                        })
                    }) 
                });
            }
        });
    }

    function deletePhotoGiris(link, callback) {
        var jobskill_ref = firestore.collection('mainScreen').where('link', '==', link);
        let batch = firestore.batch();

        jobskill_ref
            .get()
            .then(snapshot => {
                snapshot.docs.forEach(doc => {
                    batch.delete(doc.ref);
                });

                if(callback) {
                    callback();
                }

                return batch.commit();
            })
    }

    const uploadPhotoTeam = (event) => {
        var selectedFile = event.target.files[0];

        setCompressed2(selectedFile)
    };

    function uploadTeam() {
        const unique_id = Math.random();

        let ref = storage.ref(
            "team/" +
            unique_id
        );

        let uploadCard = ref.put(compressed2);

        uploadCard.then((snapshot) => {
            if (snapshot.state === "success") {
                snapshot.ref.getDownloadURL().then((url) => {
                    firestore.collection("team").add({
                        photo: url,
                        desc: document.getElementById('teamCV').value,
                        title: document.getElementById('teamTitle').value,
                        name: document.getElementById('teamName').value
                    }).then(() => {
                        setTimeout(() => {
                            window.location.reload();
                        }, 1500)
                    });
                });
            }
        });
    }

    function deletePhotoTeam(link) {
        var jobskill_ref = firestore.collection('team').where('photo', '==', link);
        let batch = firestore.batch();

        jobskill_ref
            .get()
            .then(snapshot => {
                snapshot.docs.forEach(doc => {
                    batch.delete(doc.ref);
                });
                return batch.commit();
            })
    }

    return (
        <>
            {
                isOpen ?
                    <div className="panel">
                        <h1>GALERİ</h1>
                        <input type="file" id="file" onChange={uploadPhoto} />
                        <button onClick={uploadGalery}>Yükle</button> <br /><br />
                        {
                            galeries.map((item, index) => {
                                return (
                                    <div className="portfolio-card" key={index} style={{ position: "relative", display: "inline-block", marginRight: "8px", marginBottom: "8px" }}>
                                        <button onClick={() => deletePhoto(item.link)} style={{ position: "absolute", top: 0, right: 0 }}>Sil</button>
                                        <img src={item.link} alt="galery image" className="portfolio-card-image" />
                                    </div>
                                )
                            }).reverse()
                        }

                        <br />
                        <br />
                        <br />
                        <br />


                        <h1>GİRİŞ FOTOĞRAFI</h1>
                        <input type="file" id="file" onChange={uploadPhotoGiris} />
                        <button onClick={uploadGiris}>Yükle</button> <br /><br />
                        <div className="portfolio-card" style={{ position: "relative", display: "inline-block", marginRight: "8px", marginBottom: "8px" }}>
                            {/* <button onClick={() => deletePhotoGiris(item.link)} style={{position: "absolute", top: 0, right: 0}}>Sil</button>     */}
                            <img src={main.length > 0 ? main[main.length - 1].link : ""} alt="galery image" className="portfolio-card-image" />
                        </div>

                        <br />
                        <br />
                        <br />
                        <br />

                        {/* 

                        <h1>EKİP</h1>
                        <input type="file" id="file" onChange={uploadPhotoTeam} />
                        <br />
                        <input id="teamName" type="text" placeholder="İsim girin" />
                        <br />
                        <input id="teamTitle" type="text" placeholder="Ünvan giriniz" />
                        <br />
                        <textarea id="teamCV" rows={6} placeholder="Özgeçmiş girin">
                        </textarea>
                        <br />
                        <button onClick={uploadTeam}>Yükle</button> <br /><br />
                        <div style={{
                            display: "flex",

                        }}>
                            {
                                team.map((item, index) => {
                                    return (
                                        <div className="portfolio-card" key={index} style={{
                                            border: "1px solid #eee",
                                            padding: "8px",
                                            width: "100%",
                                            maxWidth: "30%", height: "auto", maxHeight: "400px", overflow: "hidden",
                                            position: "relative", display: "inline-block", marginRight: "8px", marginBottom: "8px"
                                        }}>
                                            <button onClick={() => deletePhotoTeam(item.photo)} style={{ position: "absolute", top: 0, right: 0 }}>Sil</button>
                                            <img src={item.photo} alt="galery image" className="portfolio-card-image" style={{ height: "auto", objectFit: "contain", maxHeight: "200px" }} />
                                            <p>{item.name}</p>
                                            <p style={{ color: "orange" }}><b>{item.title}</b></p>
                                            <p>{item.desc}</p>
                                        </div>
                                    )
                                }).reverse()
                            }
                        </div> */}
                    </div>
                    :
                    ""
            }
        </>
    )
}
