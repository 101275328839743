import React, {useEffect, useState} from "react";
import "./footer.css"

import {firestore} from "../../firebase";

function useMain() {
    const [main, setMain] = useState([]);

    useEffect(() => {
        firestore.collection("mainScreen").onSnapshot((snapshot) => {
            const newMain = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }))

            setMain(newMain)
        })
    }, [])

    return main
}

export default function Footer() {
    const main = useMain();

    return (
        <div id="footer">
            <div className="container">
                <div className="footer-table">
                    <div className="footer-cell">
                        <div className="footer-heading">İletişim</div>
                        <div className="footer-about">
                            <div className="footer-contact-info"><i className="fas fa-street-view"></i> Alsancak, Kıbrıs Şehitleri Cd. No:66, 35220 Konak/İzmir</div>
                            <div className="footer-contact-info"><i className="fas fa-envelope"></i>  donkisottattoo@gmail.com</div>
                            <div className="footer-contact-info footer-contact-phone"><i className="fas fa-phone"></i> +90 507 566 93 81</div>
                        </div>
                    </div>
                    <div className="footer-cell">
                        <div className="footer-heading">Hizmetler</div>
                        <div className="footer-about">
                            <div className="footer-services-list">
                                <li className="footer-services-list-item">Dövme Tasarımı</li>
                                <li className="footer-services-list-item">Dövme Uygulaması</li>
                            </div>
                        </div>
                    </div>
                    <div className="footer-cell">
                        <div className="footer-logo">Donkişot Tattoo</div>
                        <div className="footer-about">
                            {main.length > 0 ? main[0].desc : ""}
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="footer-bottom-table">
                        <div className="footer-bottom-cell">Tüm hakları saklıdır. <span><i className="fas fa-copyright"></i></span> Donkişot Tattoo</div>
                        <div className="footer-bottom-cell text-right">
                            <ul className="social-media-list">
                                <li className="social-media-list-item"><a href="https://www.facebook.com/donkisottattoo/" target="_blank"><i className="fab fa-facebook"></i></a></li>
                                <li className="social-media-list-item"><a href="https://www.instagram.com/donkisottattoostudio" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                <li className="social-media-list-item"><a href="mailto:donkisottattoo@gmail.com" target="_blank"><i className="fas fa-at"></i></a></li>
                                <li className="social-media-list-item"><a href="https://www.instagram.com/donkisottattoostudio" target="_blank"><i className="fab fa-youtube"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}